<template>
  <div class="downLoad">
    <a-button
      type="primary"
      :loading="isLoading"
      icon="download"
      @click="getFilePathAndDowloadFile"
    >
      {{ isLoading ? 'Loading' : 'Download File' }}
    </a-button>
  </div>
</template>

<script>
// import socketError from '../../utils/socketError';
import path from 'path';
// import querystring from 'querystring';
const FileDownload = require('js-file-download');

export default {
  props: {
    downLoadUrl: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      path,
      isLoading: false,
      filePath: '',
      axios,
    };
  },
  methods: {
    getFilePathAndDowloadFile(downloadFormat = 'csv') {
      this.isLoading = true;
      const selectedSiteId = localStorage.getItem('selectedPartner');
      const searchParams = new window.URLSearchParams(this.searchParams).toString();
      this.filePath = `${process.env.VUE_APP_API_URL}${this.$route.path}-download-link-by-id?download_token=${this.token}&notFormPage=true&jwtToken=${localStorage.getItem('token')}&${searchParams}&downloadFormat=${downloadFormat}&selectedSiteId=${selectedSiteId}`;
      const url = `${process.env.VUE_APP_API_URL}${this.$route.path}-download-link-by-id`;
      axios({
        url: this.downLoadUrl || url,
        method: 'GET',
        params: {
          download_token: this.token,
          notFormPage: true,
          jwtToken: localStorage.getItem('token'),
          downloadFormat,
          selectedSiteId,
          ...this.searchParams,
        },
      }).then((res) => {
        try {
          const response = JSON.parse(res.request.response);
          if (response.success) {
            this.openNotificationWithIcon('success', response.message);
          }
        } catch {
          FileDownload(res.request.response, res.headers.filename);
        }
      });
      this.isLoading = false;
    },
    openNotificationWithIcon(type, message, duration = 4) {
      this.$notification[type]({
        message: type,
        top: '20%',
        duration,
        description: message,
      });
    },
  },
};
</script>

<style lang="scss">
.downLoad{
  text-align: right;
  .ant-btn-primary{
    i{
      color: white!important;
      margin: 0!important;
    }
  }
}
</style>
